// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	appVersion: "v4.0",
	USERDATA_KEY: "authf649fc9a5f55",
	isMockEnabled: true,
	type: "staging",
	sentry_dsn:
		"https://0fd4dce63f8adf8ea535bb7931d577af@o4505907318816768.ingest.sentry.io/4506382628159488",
	traces_sample_rate: 0.5,
	replays_on_error_sample_rate: 0.5,
	replays_session_sample_rate: 0.1,
	api: {
		url: "https://api.staging.askeddi.com",
	},
	msal: {
		tenant: "common",
		clientId: "a7b9110f-1ab4-450b-822c-c2f7b9547ba3",
		redirectUri: "https://staging.askeddi.com/auth/login",
	},
	google: {
		clientId:
			"207582784092-tolhdje7iv7seu37am7a9orjmqfj1q8a.apps.googleusercontent.com",
	},
	// stripe_pk: "pk_test_Z0nuedUSwnp544suhN6JJSJH00ShgsI4Lk",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
